import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useCategoryOverviewStore from '~hooks/store/useCategoryOverviewStore';
import Subcategory from '../Subcategory';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const SubcategoriesMobile = ({ categories }) => {
  const classes = useStyles();
  const currentCategoryUid = useCategoryOverviewStore(state => state.currentCategoryUid);

  const selectedCategory = useMemo(
    () => categories.find(category => category.uid === currentCategoryUid),
    [categories, currentCategoryUid]
  );

  if (!selectedCategory || !selectedCategory.resources) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Subcategory key={selectedCategory?.uid} {...selectedCategory} />
    </div>
  );
};

SubcategoriesMobile.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      navigationTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      calendarDate: PropTypes.string,
      parentCategory: PropTypes.shape({
        uid: PropTypes.string,
        title: PropTypes.string
      }),
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          uid: PropTypes.string,
          url: PropTypes.string,
          isSubscriberResource: PropTypes.bool,
          urlTarget: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
          resourceMedium: PropTypes.string.isRequired,
          duration: PropTypes.string,
          episodeCountText: PropTypes.string,
          metaText: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.shape({
            alt: PropTypes.string,
            url: PropTypes.string
          })
        })
      )
    })
  ).isRequired
};

export default SubcategoriesMobile;
