import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useCategoryOverviewStore from '~hooks/store/useCategoryOverviewStore';
import { getNavigationItems } from './utils';
import NavItem from './NavItem';
import Calendar from './Calendar';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18
  }
}));

const DesktopNavigation = ({ categories, color }) => {
  const classes = useStyles();
  const navigationItems = useMemo(() => getNavigationItems(categories), [categories]);
  const currentCategoryUid = useCategoryOverviewStore(state => state.currentCategoryUid);

  const showCalendar = useMemo(
    () => (categories || []).some(category => category.calendarDate),
    [categories]
  );

  const selectedCategory = useMemo(() => {
    if (currentCategoryUid) {
      return categories.find(category => category.uid === currentCategoryUid);
    }
    return null;
  }, [categories, currentCategoryUid]);

  const activeUids = useMemo(() => {
    if (selectedCategory) {
      const uid = selectedCategory?.uid;
      const parentUid = selectedCategory?.parentCategory?.uid;
      return parentUid ? [parentUid, uid] : [uid];
    }
    return [];
  }, [selectedCategory]);

  return (
    <div className={classes.root}>
      {showCalendar && <Calendar color={color} selectedCategory={selectedCategory} />}
      {navigationItems.map(navItem => (
        <NavItem
          key={navItem.uid}
          color={color}
          activeUids={activeUids}
          uid={navItem.uid}
          title={navItem.title}
          items={navItem.items}
        />
      ))}
    </div>
  );
};

DesktopNavigation.propTypes = {
  color: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uid: PropTypes.string,
      navigationTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      calendarDate: PropTypes.string,
      calendarEndDate: PropTypes.string,
      parentCategory: PropTypes.shape({
        uid: PropTypes.string,
        title: PropTypes.string
      })
    })
  ).isRequired
};

export default DesktopNavigation;
