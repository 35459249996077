import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import LibraryCategoryItem from '~components/cards/LibraryCategoryItem';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    scrollMarginTop: '213px',
    paddingTop: 10,
    paddingBottom: 10
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 10
  },
  title: {
    color: 'black',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: '600',
    marginTop: 0,
    marginBottom: 0
  },
  resourceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    marginTop: 13,
    marginBottom: 13
  }
}));

const Subcategory = ({ uid, displayTitle, resources }) => {
  const classes = useStyles();
  const titleRef = useRef(null);

  return (
    <section className={classes.root} id={uid}>
      <div className={classes.titleContainer} ref={titleRef}>
        <h3 className={classes.title}>{displayTitle}</h3>
      </div>
      <div className={classes.resourceContainer}>
        {resources.map(resource => (
          <LibraryCategoryItem key={`${uid}-${resource.uid}`} {...resource} />
        ))}
      </div>
    </section>
  );
};

Subcategory.propTypes = {
  uid: PropTypes.string.isRequired,
  displayTitle: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      url: PropTypes.string,
      isSubscriberResource: PropTypes.bool,
      urlTarget: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      resourceMedium: PropTypes.string.isRequired,
      duration: PropTypes.string,
      episodeCountText: PropTypes.string,
      metaText: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string
      })
    })
  ).isRequired
};

Subcategory.defaultProps = {};

export default Subcategory;
