/**
 * Returns an array of navigation items grouped by parent category.
 *
 * @param {Array} categories - The array of categories.
 * @returns {Array} - The array of navigation items grouped by parent category.
 */
export const getNavigationItems = categories => {
  if (!categories) {
    return [];
  }
  const groupedByParent = categories.reduce((res, category) => {
    if (!category) {
      return res;
    }

    const item = {
      uid: category.uid,
      title: category.navigationTitle
    };
    // when the category has a parent, let's add it under parent
    if (category?.parentCategory?.uid) {
      if (!res[category.parentCategory.uid]) {
        res[category.parentCategory.uid] = {
          uid: category.parentCategory.uid,
          title: category.parentCategory.title,
          items: []
        };
      }
      res[category.parentCategory.uid].items.push(item);
    } else if (!res[category.uid]) {
      // category is not seen yet and it doesn't have a parent,
      // so let's add it to the root
      res[category.uid] = { ...item, items: [] };
    }
    return res;
  }, {});

  return Object.values(groupedByParent);
};
