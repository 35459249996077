import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Link from '~components/Link';
import Carrot from '~images/buttons/carrot.inline.svg';

const useStyles = makeStyles(theme => ({
  nav: {
    [theme.breakpoints.up('xs')]: {
      position: 'sticky',
      top: '68px',
      width: '100%',
      backgroundColor: '#ffffff',
      zIndex: 50
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '10px',
    padding: '48px 0px 64px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: 16,
      marginBottom: 16
    }
  },
  mobileLink: {
    color: 'black',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '33px',
    '& > svg': {
      rotate: '180deg',
      marginRight: 12
    }
  },
  link: {
    color: '#000000',
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '33px',
    '&:hover': {
      fontWeight: '600',
      letterSpacing: -0.2
    }
  },
  activeLink: {
    color: '#000000',
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '33px'
  },
  divider: {
    marginTop: 0,
    marginBottom: 0,
    color: '#000000',
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '33px'
  }
}));

const CategoryTopNavigation = ({ links }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const lastNonActiveLink = useMemo(() => {
    const allNonActiveLinks = links.filter(link => !link.isActive);
    return allNonActiveLinks[allNonActiveLinks.length - 1];
  }, [links]);

  if (isMobile) {
    return (
      <div className={classes.root}>
        {lastNonActiveLink && (
          <Link to={lastNonActiveLink.url} className={classes.mobileLink} underline="none">
            <Carrot stroke="#000000" rotate="180deg" />
            {lastNonActiveLink.mobileTitle}
          </Link>
        )}
      </div>
    );
  }

  return (
    <nav className={classes.nav}>
      <div className={classes.root}>
        {links.map((link, index) => (
          <React.Fragment key={link?.title}>
            {index !== 0 && <h4 className={classes.divider}>/</h4>}
            <Link
              to={link?.url}
              className={link?.isActive ? classes.activeLink : classes.link}
              underline="none"
            >
              {link?.title}
            </Link>
          </React.Fragment>
        ))}
      </div>
    </nav>
  );
};

CategoryTopNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      mobileTitle: PropTypes.string,
      url: PropTypes.string,
      isActive: PropTypes.bool
    })
  ).isRequired
};

export default CategoryTopNavigation;
