import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useCategoryOverviewStore from '~hooks/store/useCategoryOverviewStore';
import Link from '~components/Link';
import clsx from '~styles/clsx';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  scrollableContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    gap: 8,
    overflowX: 'scroll',
    scrollbarWidth: 'none'
  },
  navItem: {
    color: '#000000',
    backgroundColor: '#F2F2F2',
    padding: '8px 15px 6px',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '500',
    borderRadius: '30px'
  },
  selectedNavItem: {
    border: '1px black solid'
  },
  parentItemsContainer: {
    marginBottom: 14,
    gap: '16px !important'
  },
  navParentItem: {
    color: '#B5B5B5',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '600'
  },
  selectedNavParentItem: {
    color: '#000000 !important'
  }
}));

const MobileNavigation = ({ categories, pagePath }) => {
  const classes = useStyles();

  const parentNavigationItems = useMemo(() => {
    const parentMap = categories.reduce((res, category) => {
      if (category.parentCategory) {
        const parentUid = category.parentCategory.uid;
        if (!res[parentUid]) {
          res[parentUid] = {
            ...category.parentCategory,
            firstChildUid: category.uid
          };
        }
      }
      return res;
    }, {});

    return Object.values(parentMap);
  }, [categories]);

  const currentCategoryUid = useCategoryOverviewStore(state => state.currentCategoryUid);

  const selectedCategory = useMemo(() => {
    if (currentCategoryUid) {
      return categories.find(category => category.uid === currentCategoryUid);
    }
    return null;
  }, [categories, currentCategoryUid]);

  const navigationItems = useMemo(() => {
    if (selectedCategory?.parentCategory) {
      return categories.filter(
        category => category.parentCategory.uid === selectedCategory.parentCategory.uid
      );
    }
    return categories;
  }, [selectedCategory, categories]);

  return (
    <div className={classes.root}>
      {parentNavigationItems.length > 0 && (
        <div className={clsx(classes.scrollableContainer, classes.parentItemsContainer)}>
          {parentNavigationItems.map(navParentItem => (
            <Link
              key={navParentItem.uid}
              id={`browse-nav-parent-${navParentItem.uid}`}
              to={`${pagePath}?category=${navParentItem.firstChildUid}`}
              className={clsx(
                classes.navParentItem,
                selectedCategory?.parentCategory?.uid === navParentItem.uid &&
                  classes.selectedNavParentItem
              )}
              underline="none"
            >
              {navParentItem?.title}
            </Link>
          ))}
        </div>
      )}
      <div className={classes.scrollableContainer}>
        {navigationItems.map(navItem => (
          <Link
            key={navItem.uid}
            id={`browse-nav-child-${navItem.uid}`}
            to={`${pagePath}?category=${navItem.uid}`}
            className={clsx(
              classes.navItem,
              currentCategoryUid === navItem.uid && classes.selectedNavItem
            )}
            underline="none"
          >
            {navItem?.navigationTitle}
          </Link>
        ))}
      </div>
    </div>
  );
};

MobileNavigation.propTypes = {
  pagePath: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uid: PropTypes.string,
      navigationTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      calendarDate: PropTypes.string,
      calendarEndDate: PropTypes.string,
      parentCategory: PropTypes.shape({
        uid: PropTypes.string,
        title: PropTypes.string
      })
    })
  ).isRequired
};

export default MobileNavigation;
