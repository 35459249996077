import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { navigate } from 'gatsby';
import ClickableText from '~components/buttons/ClickableText';
import Carrot from '~images/buttons/carrot.inline.svg';
import clsx from '~styles/clsx';
import Link from '~components/Link';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer'
  },
  accordionChildren: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: 18,
    borderLeft: '2px #E8E8E8 solid'
  },
  link: {
    fontSize: 14,
    fontFamily: 'Inter'
  },
  childLink: {
    fontSize: 14,
    fontFamily: 'Inter',
    marginLeft: 16
  },
  inactiveParentLink: {
    color: '#7F7F7F',
    fontWeight: '500'
  },
  inactiveChildLink: {
    color: '#AFAFAF',
    fontWeight: '500'
  },
  activeParentLink: {
    color: props => props.color || '#000000',
    fontWeight: '700'
  },
  activeChildLink: {
    color: props => props.color || '#000000',
    fontWeight: '600'
  },
  expandedCarrot: {
    rotate: '90deg',
    marginRight: '2px'
  }
}));

const NavItem = ({ uid, title, items, activeUids, color }) => {
  const classes = useStyles({ color });
  const [isManuallyExpanded, setIsManuallyExpanded] = useState(null);
  const isActive = (activeUids || []).includes(uid);
  const isExpanded = isManuallyExpanded != null ? isManuallyExpanded : isActive;

  useEffect(() => {
    // reset manual expansion when active uids get updated
    setIsManuallyExpanded(null);
  }, [activeUids]);

  const onExpandRootCategory = () => {
    setIsManuallyExpanded(expanded => !expanded);
    navigate(`#${items[0].uid}`);
  };

  if (items?.length > 0) {
    return (
      <div className={classes.root}>
        <ClickableText
          className={clsx(
            classes.link,
            classes.accordionItem,
            isActive ? classes.activeParentLink : classes.inactiveParentLink
          )}
          onClick={onExpandRootCategory}
          component="div"
        >
          <div>{title}</div>
          <Carrot
            stroke={isActive ? color : '#C8C8C8'}
            className={clsx(isExpanded && classes.expandedCarrot)}
          />
        </ClickableText>
        {isExpanded && (
          <div className={classes.accordionChildren}>
            {items.map(item => (
              <Link
                key={item.uid}
                to={`#${item.uid}`}
                className={clsx(
                  classes.childLink,
                  activeUids.includes(item.uid)
                    ? classes.activeChildLink
                    : classes.inactiveChildLink
                )}
                underline="none"
              >
                {item.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <Link
      to={`#${uid}`}
      className={clsx(
        classes.link,
        activeUids.includes(uid) ? classes.activeParentLink : classes.inactiveParentLink
      )}
      underline="none"
    >
      {title}
    </Link>
  );
};

NavItem.propTypes = {
  color: PropTypes.string.isRequired,
  activeUids: PropTypes.arrayOf(PropTypes.string),
  uid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      title: PropTypes.string
    })
  )
};

NavItem.defaultProps = {
  activeUids: [],
  items: []
};

export default NavItem;
