import { useEffect } from 'react';
import useCategoryOverviewStore from '~hooks/store/useCategoryOverviewStore';
import { useLocation } from '@reach/router';

export default (categories, pagePath) => {
  const [currentCategoryUid, updateCurrentCategoryUid] = useCategoryOverviewStore(state => [
    state.currentCategoryUid,
    state.updateCurrentCategoryUid
  ]);
  const location = useLocation();

  // default navigation to first category
  useEffect(() => {
    if (!currentCategoryUid && categories.length > 0) {
      updateCurrentCategoryUid(categories[0].uid);
    }
  }, [currentCategoryUid, categories, pagePath, updateCurrentCategoryUid]);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const urlParamCategory = search.get('category');
    const selectedCategory = categories.find(category => category.uid === urlParamCategory);
    if (urlParamCategory && selectedCategory) {
      updateCurrentCategoryUid(urlParamCategory);
    }
  }, [location.search]);

  useEffect(
    () => () => {
      // let's reset the selected category when the component unmounts
      updateCurrentCategoryUid(null);
    },
    [updateCurrentCategoryUid]
  );

  return null;
};
